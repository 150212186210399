<!-- Start SaaS Main Banner -->
<div class="main-banner saas-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/saas-shape/arrow.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <img src="assets/img/saas-shape/main-image.png" class="wow zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>The Source of Creation</h1>
                            <p><a routerLink="/contact-us" class="btn btn-primary">Join with us</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start Features Area -->
<section class="features-area saas-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>HN Media Group</h2>
            <div class="bar"></div>
            <p>We have 8 years of experience working with Youtube with domestic and foreign partners. With many years of experience, we are confident in the process of producing and releasing the best quality music works. Along with that is the development of strong Youtube channels and channels.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-youtube"></i>
                    </div>
                    <h3>Youtube</h3>
                    <p>Youtube is our top strength. And music is an area that we love. In addition, with great potential and long experience, we are always confident in other genres.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-facebook"></i>
                    </div>
                    <h3>Facebook</h3>
                    <p>Facebook is also the choice that we are developing. With a large fan base and a growing online community. So Facebook would be a great place too..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-ui-music-player"></i>
                    </div>
                    <h3>TikTok</h3>
                    <p>In addition, with a fast pace of development in a short time. Tiktok is gradually asserting its position..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-bullseye"></i>
                    </div>
                    <h3>Produce</h3>
                    <p>We are focusing on content production. To be able to supply and cooperate with major partners in the world..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-technorati"></i>
                    </div>
                    <h3>Technology</h3>
                    <p>In addition to applying the best external tools to serve the analysis, development and job security. We also have our own technology products.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-ui-social-link"></i>
                    </div>
                    <h3>Other platforms</h3>
                    <p>Companioning and supporting growth artist's fanbase via professional social network.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-bullseye"></i>
                            </div>
                            <h3>Content-oriented</h3>
                            <p>Choose the right content type. Manufacturing or cooperating with other suppliers. Content quality is the most important factor to achieve set goals.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-edit"></i>
                            </div>
                            <h3>Optimization and development</h3>
                            <p>Make a detailed plan. Incorporation of essential tools for analysis and evaluation. Finally, building the development process.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-match-review"></i>
                            </div>
                            <h3>Evaluation and measurement</h3>
                            <p>Content review. Analyze the decisive factors that affect the development speed of Youtube channel. Update and add new ways to have a perfect process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-50 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>For Youtube in particular</h2>
            <div class="bar"></div>
            <p>In the field of Youtube channel development. We have achieved certain results:</p>
        </div>
    </div>
</section>
<!-- End SAAS Tools Area -->
<!-- Start Fun Facts Area -->
<section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-youtube"></i>
                    </div>
                    <h3><span class="count">50+</span></h3>
                    <p>Youtube Channels</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-people"></i>
                    </div>
                    <h3><span class="count">5,000,000+ </span></h3>
                    <p>Subscribers</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-eye-alt"></i>
                    </div>
                    <h3><span class="count">10,000,000,000+</span></h3>
                    <p>Views</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-youtube-play"></i>
                    </div>
                    <h3><span class="count">5+</span></h3>
                    <p>Gold Play Button</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->
<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
		 <div class="section-title">
            <h2>Our Partner</h2>
            <div class="bar"></div>
		</div>
		<div class="row">

			<div class="col-lg-4 col-md-6">
				<div class="single-team-member">
					<img src="assets/img/WMG.png" alt="team">
				</div>
			</div>

			<div class="col-lg-4 col-md-6">
				<div class="single-team-member">
					<img src="assets/img/HGmedia.png" alt="team">
				</div>
			</div>

			<div class="col-lg-4 col-md-6">
				<div class="single-team-member">
					<img src="assets/img/Sa_Re_Ga_Ma.png" alt="team">
				</div>
			</div>

			<div class="col-lg-4 col-md-6">
				<div class="single-team-member">
					<img src="assets/img/yt.png" alt="team">
				</div>
			</div>
		</div>
    </div>
</section>
<!-- End Team Area -->
<!--<app-partner></app-partner>-->