<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>About Us</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>HN Media Group - The Source of Creation.</h2>
                        <div class="bar"></div>
                        <p>HN Media Group is a unit established on October 14, 2020. We have 8 years of experience working with Youtube with domestic and foreign partners.</p>
                    </div>
                    <p>By the end of 2020, we officially established HN Media Group Company. With many years of experience, we are confident in the content production process, release high quality music works both in sound and visual. Along with that is the development of strong Youtube channels and channels.</p>
                    <p>In addition to working with reputable domestic partners (Hong An Entertainment, HG Media) We also collaborate with international partners (Wanner music, Saregama…) to want to develop in the world market.</p>
					<p>HN Media Group always focuses on the creation of well-invested works. We have a team of experts with many years of experience in the field of youtube, bring value to users as well as partners who collaborate.</p>
					<p>HN Media Group also cooperates with reputable lawyers, Long experience in copyright to ensure the rights of all copyright owners.</p>
					<p>For more information, please contact via:</p>
					<p>Email: contacts@hnmedia.net<br/>Phone: +84904789422</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/marketing.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->
<!--<app-partner></app-partner>-->