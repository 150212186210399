<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <!-- <div class="item"> -->
                <!-- <a target="_blank" href="#"><img src="assets/img/partner-1.png" alt="partner"></a> -->
            <!-- </div> -->

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-2.png" alt="partner"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-3.png" alt="partner"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-4.png" alt="partner"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-5.png" alt="partner"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-6.png" alt="partner"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="assets/img/partner-7.png" alt="partner"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->